<template>
  <b-modal ref="subtitle-modal" hide-title size="lg" hide-footer centered>
    <template #modal-header="{ close }">
      <h5>{{ $t("video-subtitles") }}</h5>
      <svg-cross-icon class="cross text-right" @click="close()" />
    </template>
    <p class="text">
      {{ $t("subtitles-info-1") }}
      <br />
      {{ $t("subtitles-info-2") }}
    </p>
    <img src="/subtitles.png" />
  </b-modal>
</template>

<script>
export default {
  components: {
    "svg-cross-icon": () => import("/assets/icons/cross.svg?inline"),
  },

  methods: {
    showModal() {
      this.$nextTick(() => {
        this.$refs["subtitle-modal"].show();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  margin-bottom: 1.5rem;
}
</style>
